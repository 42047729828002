import React from "react";
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Layout02 = ({ children }) => {
  
  return (
    <section className = "relative bg-blue-50 select-none">
      <div 
        className = "text-white min-h-screen flex items-center bg-gradient-to-r from-gray-900 to-gray-800"
      >
        <div className="container mx-auto p-4 flex flex-wrap items-center">
          <div className="w-full md:w-7/12 text-center md:text-left p-4">
            
            <div className="text-6xl font-medium">404</div>
           
            <div className="text-xl md:text-3xl font-medium mb-4">
              PÁGINA NO ENCONTRADA
            </div>
            
            <div className="text-lg mb-8">
              En enlace ingresado no existe.
            </div>
            

            <Link
              to={`/`}
              className=" bg-transparent hover:bg-white hover:text-gray-800 text-white font-semibold py-2 px-4 border-2 border-white hover:border-transparent rounded "
            >
              PÁGINA PRINCIPAL
            </Link>

          </div>
        </div>
      </div>

      <main>{children}</main>
    </section>
  )
}

Layout02.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout02
