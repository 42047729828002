import React from "react"

import Layout from "../components/layouts/layout02"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title = "Inmovisor | Página No Encontrada" />
  </Layout>
)

export default NotFoundPage
